import { createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";
const initialState = {
 userId: (JSON.parse(localStorage.getItem("tokenData")) || {}).userId || "",
 firstName: "",
 lastName: "",
 email: "",
 password: "",
 firstNameError: "",
 lastNameError: "",
 emailError: "",
 passwordError: "",
 signupError: "",
 signupSuccess: "",
 loginError: "",
 loginSuccess: "",
 passwordResetError: "",
 passwordResetSuccess: "",
 accessToken: (JSON.parse(localStorage.getItem("tokenData")) || {}).accessToken || null,
 refreshToken: (JSON.parse(localStorage.getItem("tokenData")) || {}).refreshToken || null,
};
const authSlice = createSlice({
 name: "auth",
 initialState,
 reducers: {
 setFirstName: (state, action) => {
 state.firstName = action.payload;
 },
 setLastName: (state, action) => {
 state.lastName = action.payload;
 },
 setEmail: (state, action) => {
 state.email = action.payload;
 },
 setPassword: (state, action) => {
 state.password = action.payload;
 },
 setFirstNameError: (state, action) => {
 state.firstNameError = action.payload;
 },
 setLastNameError: (state, action) => {
 state.lastNameError = action.payload;
 },
 setEmailError: (state, action) => {
 state.emailError = action.payload;
 },
 setPasswordError: (state, action) => {
 state.passwordError = action.payload;
 },
 setSignupError: (state, action) => {
 state.signupError = action.payload;
 },
 setSignupSuccess: (state, action) => {
 state.signupSuccess = action.payload;
 },
 setLoginError: (state, action) => {
 state.loginError = action.payload;
 },
 setLoginSuccess: (state, action) => {
 state.loginSuccess = action.payload;
 },
 setPasswordResetError: (state, action) => {
 state.passwordResetError = action.payload;
 },
 setPasswordResetSuccess: (state, action) => {
 state.passwordResetSuccess = action.payload;
 },
 setUser: (state, action) => {
const { userId, firstName, lastName, email } = action.payload;
 state.userId = userId;
 state.firstName = firstName;
 state.lastName = lastName;
 state.email = email;
 },
 setTokens: (state, action) => {
const { accessToken, refreshToken, userId } = action.payload;
const tokenData = { accessToken, refreshToken, userId };
 localStorage.setItem("tokenData", JSON.stringify(tokenData));
 state.accessToken = accessToken;
 state.refreshToken = refreshToken;
 state.userId = userId;
 },
 clearTokens: (state) => {
 state.accessToken = null;
 state.refreshToken = null;
 state.userId = "";
 localStorage.removeItem("tokenData");
 },
 },
 });
export const {
 setFirstName,
 setLastName,
 setEmail,
 setPassword,
 setFirstNameError,
 setLastNameError,
 setEmailError,
 setPasswordError,
 setSignupError,
 setSignupSuccess,
 setLoginError,
 setLoginSuccess,
 setPasswordResetError,
 setPasswordResetSuccess,
 setUser,
 setTokens,
 clearTokens,
} = authSlice.actions;
export default authSlice.reducer