import React from 'react';
import './OrderModal.css';
import { Image_URL } from '../../../../config/config';

const OrderModal = ({ order, onClose }) => {
  if (!order) return null;

  const billingDetails = order.BillingDetails && order.BillingDetails[0] ? order.BillingDetails[0] : {};
  const orderDetail = order.OrderDetails && order.OrderDetails[0] ? order.OrderDetails[0] : {};
  const productImage = `${Image_URL}products/thumbnails/${orderDetail.Sources?.split(',')[0]}`;

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        <h2>Order Information</h2>
        <div className="order-details">
          <img src={`${Image_URL}products/thumbnails/${orderDetail.Sources?.split(',')[0]}`} alt="Product" />
          <div className="product-info">
            <h3>{orderDetail.Title}</h3>
            <p>SKU: <span>{orderDetail.Slug}</span></p>
            <p>Variant: <span>{orderDetail.Color}</span></p>
            <p>Qty: <span>{orderDetail.Qty}</span></p>
            <p>Amount: <span>Rs. {order.OrderAmount}</span></p>
          </div>
        </div>
        <div className="billing-info">
          <h3>Billing Information</h3>
          <div className="info-grid">
            <p>Name</p>
            <p>{billingDetails.FirstName} {billingDetails.LastName}</p>
            <p>Email</p>
            <p>{billingDetails.Email}</p>
            <p>Contact Number</p>
            <p>{billingDetails.Phone}</p>
            <p>Address</p>
            <p>{billingDetails.Address1}, {billingDetails.Address2}, {billingDetails.City}, {billingDetails.Country}, {billingDetails.PostalCode}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderModal;
