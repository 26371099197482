import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./productcarouselComponent.css";
import ProductCard from "../ProductCardComponent/Index";

const HorizontalCarousel = ({ products, onMainImageClick }) => {
  console.log('HorizontalCarousel received products:', products);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 3,
    nextArrow: (
      <div className="arrow next">
        <i className="fas fa-chevron-right" />
      </div>
    ),
    prevArrow: (
      <div className="arrow prev">
        <i className="fas fa-chevron-left" />
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <Slider {...settings}>
        {products.map((product) => {
          console.log('Rendering ProductCard for:', product);
          return (
            <ProductCard 
              key={product.ProductId} 
              product={product} 
              onMainImageClick={onMainImageClick} 
            />
          );
        })}
      </Slider>
    </div>
  );
};

export default HorizontalCarousel;