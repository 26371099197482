import { useState , useEffect} from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Logo from '../../../assests/logo.png';
import './Navbar.css';
import SearchModal from '../modalComponent/SearchModal/SearchModal';
import { fetchCategories, fetchSubCategories, fetchCollections } from '../../../Services/CategoryServices';

const NavbarComponent = () => {
  const [showCategoriesDropdown, setShowCategoriesDropdown] = useState(false);
  const [showCollectionsDropdown, setShowCollectionsDropdown] = useState(false);
  const [hoveredCategory, setHoveredCategory] = useState(null);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [collections, setCollections] = useState([]);
  const wishlistCount = useSelector((state) => state.wishlist.wishlistProductIds.length);
  const cartCount = useSelector((state) => state.cart.items.length);
  const navigate = useNavigate();

  useEffect(() => {
    const getCategoriesAndCollections = async () => {
      try {
        console.log('Fetching categories...');
        const categories = await fetchCategories();
        console.log('Categories fetched:', categories);

        console.log('Fetching subcategories...');
        const subCategories = await fetchSubCategories();
        console.log('Subcategories fetched:', subCategories);

        const categoriesWithSubCategories = categories.map((category) => ({
          ...category,
          subCategories: subCategories.filter((subCategory) => subCategory.CategoryId === category.CategoryId),
        }));
        console.log('Categories with subcategories:', categoriesWithSubCategories);
        setCategories(categoriesWithSubCategories);

        console.log('Fetching collections...');
        const collections = await fetchCollections();
        console.log('Collections fetched:', collections);
        setCollections(collections);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    getCategoriesAndCollections();
  }, []);

  const handleSearchModalOpen = () => {
    setShowSearchModal(true);
  };

  const handleSearchModalClose = () => {
    setShowSearchModal(false);
  };

  const handleCategoryClick = (slug) => {
    navigate(`/category/${slug}`);
  };

  const handleSubCategoryClick = (slug) => {
    navigate(`/subcategory/${slug}`);
  };

  const handleCollectionClick = (slug) => {
    navigate(`/collection/${slug}`);
  };

  return (
    <>
      <Navbar variant="dark" expand="lg" className="navbar">
        <div className="container">
          <Container className="d-flex align-items-center justify-content-between custom-container">
            <div className="d-flex align-items-center">
              <Navbar.Brand href="#home" className="navbar-brand">
                <img src={Logo} className="logo" alt="Logo" />
              </Navbar.Brand>
              <div className="nav-icons ml-2 d-lg-none">
                <i className="fa-regular fa-user"></i>
                <i className="fa-solid fa-cart-shopping"></i>
                <i className="fa-regular fa-heart"></i>
              </div>
            </div>
            <Navbar.Toggle aria-controls="basic-navbar-nav" className="navbar-toggler" />
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
              <Nav className="nav-center">
                <Nav.Link as={Link} to="/home" className="nav-link">
                  Home
                </Nav.Link>
                <div
                  className="nav-item dropdown"
                  onMouseEnter={() => setShowCategoriesDropdown(true)}
                  onMouseLeave={() => setShowCategoriesDropdown(false)}
                >
                  <Nav.Link className="nav-link d-flex align-items-center">
                    Categories <i className="fa-solid fa-chevron-down ml-1"></i>
                  </Nav.Link>
                  {showCategoriesDropdown && (
                    <div className="dropdown-menu show">
                      {categories.map((category) => (
                        <div
                          key={category.CategoryId}
                          className="dropdown-submenu"
                          onMouseEnter={() => setHoveredCategory(category.CategoryId)}
                          onMouseLeave={() => setHoveredCategory(null)}
                        >
                          <div className="dropdown-item" onClick={() => handleCategoryClick(category.Slug)}>
                            {category.Category}
                          </div>
                          {hoveredCategory === category.CategoryId && (
                            <div className="dropdown-menu show">
                              {category.subCategories.map((subCategory) => (
                                <div
                                  key={subCategory.SubCategoryId}
                                  className="dropdown-item"
                                  onClick={() => handleSubCategoryClick(subCategory.Slug)}
                                >
                                  {subCategory.SubCategory}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div
                  className="nav-item dropdown"
                  onMouseEnter={() => setShowCollectionsDropdown(true)}
                  onMouseLeave={() => setShowCollectionsDropdown(false)}
                >
                  <Nav.Link className="nav-link d-flex align-items-center">
                    Collections <i className="fa-solid fa-chevron-down ml-1"></i>
                  </Nav.Link>
                  {showCollectionsDropdown && (
                    <div className="dropdown-menu show">
                      {collections.map((collection) => (
                        <div
                          key={collection.CollectionId}
                          className="dropdown-item"
                          onClick={() => handleCollectionClick(collection.Slug)}
                        >
                          {collection.Collection}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <Nav.Link as={Link} to="/about-us" className="nav-link">
                  About
                </Nav.Link>
                <Nav.Link as={Link} to="/contact-us" className="nav-link">
                  Contact Us
                </Nav.Link>
              </Nav>
              <div className="nav-icons d-none d-lg-flex ml-auto">
                <Link to="#" onClick={handleSearchModalOpen}>
                  <i className="fa-solid fa-magnifying-glass"></i>
                </Link>
                <Link to="/profile">
                  <i className="fa-regular fa-user"></i>
                </Link>
                <Link to="/cart">
                  <i className="fa-solid fa-cart-shopping"></i>
                </Link>
                <i className="fa-regular fa-heart"></i>
              </div>
            </Navbar.Collapse>
          </Container>
        </div>
      </Navbar>
      <SearchModal show={showSearchModal} handleClose={handleSearchModalClose} />
    </>
  );
};

export default NavbarComponent;