import React from 'react';
import './Testimonials.css';

const ReviewCard = ({ review }) => {
  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <span key={i} className={i <= rating ? "filled-star" : "empty-star"}>
          &#9733;
        </span>
      );
    }
    return stars;
  };

  return (
    <div className="review-card mb-3">
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="d-flex align-items-center">
            <div className="star-rating">
              {renderStars(review.rating)}
            </div>
            <span className="review-time ml-3">{review.time}</span>
          </div>
          <img src={review.avatar} alt="avatar" className="rounded-circle" width="50" height="50" />
        </div>
        <p className="mt-3 review-text">{review.text}</p>
        <p className="review-name">-{review.name}</p> {/* Ensure the dash and name start on a new line */}
      </div>
    </div>
  );
};

export default ReviewCard;
