import React, { useState, useEffect } from 'react';
import ProductCard from '../../components/common/ProductCardComponent/Index';
import Header from '../../assests/Header.png';
import FilterSidebar from '../../components/FilterSidebar/index';
import ReactPaginate from 'react-paginate';
import { fetchProductData } from '../../Services/ProductServices';
import './ProductsList.css';

const ProductList = () => {
  const [allProducts, setAllProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const itemsPerPage = 12;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const productsData = await fetchProductData();
        setAllProducts(productsData);
        setFilteredProducts(productsData);
      } catch (error) {
        setError('Failed to fetch products. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const getPagedData = () => {
    const offset = currentPage * itemsPerPage;
    return filteredProducts.slice(offset, offset + itemsPerPage);
  };

  const currentItems = getPagedData();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <header className="header-image-container">
        <img src={Header} alt="Header" className="img-fluid" />
      </header>
      <div className="row mt-4 mx-0">
        <h1 className="h4 heading">All Products</h1>
        <div className="row">
          <div className="col-lg-3">
            <FilterSidebar
              allProducts={allProducts}
              setFilteredProducts={setFilteredProducts}
            />
          </div>
          <div className="col-lg-9">
            <div className="row">
              {currentItems.map(product => (
                <div className="col-lg-4 col-md-6 mb-4" key={product.ProductId}>
                  <ProductCard product={product} />
                </div>
              ))}
            </div>
            {filteredProducts.length > itemsPerPage && (
              <div className="pagination-container">
                <ReactPaginate
                  previousLabel="< Previous"
                  nextLabel="Next >"
                  breakLabel="..."
                  pageCount={Math.ceil(filteredProducts.length / itemsPerPage)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName="pagination"
                  activeClassName="active"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductList;