import React, { useState } from "react";
import "./ForgotPassword.css";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log("Password reset link sent to:", email);
  };

  return (
    <div className="forgot-password-container">
      <div className="forgot-password-card">
        <h2>Forgot Password</h2>
        <p>
          Enter the email address associated with your account and we'll send a
          link to reset your password.
        </p>
        <form className="forgot-password-form" onSubmit={handleSubmit}>
          <label className="forgot-password-label" htmlFor="email">
            Email
          </label>
          <input
            type="email"
            id="email"
            className="forgot-password-input"
            placeholder="Enter your email"
            value={email}
            onChange={handleEmailChange}
            required
          />
          <button type="submit" className="forgot-password-continue-button">
            Continue
          </button>
        </form>
        <a href="/login" className="forgot-password-back-to-login">
          Back to login
        </a>
      </div>
    </div>
  );
};

export default ForgotPassword;
