import React, { useState, useEffect } from "react";
import ProductCard from "../../components/common/ProductCardComponent/Index";
import Header from "../../assests/Header.png";
import FilterSidebar from "../../components/FilterSidebar/index";
import ReactPaginate from 'react-paginate';
import { fetchProductsBySubCategory } from '../../Services/CategoryServices';
import { useParams } from 'react-router-dom';

const SubCategoryList = () => {
    const [productData, setProductData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 12; // 3 rows x 4 columns
    const { slug } = useParams();

    useEffect(() => {
        const getProducts = async () => {
            console.log(`Fetching products for subcategory: ${slug}`);
            const fetchedProducts = await fetchProductsBySubCategory(slug);
            console.log('Fetched products:', fetchedProducts);
            setProductData(fetchedProducts);
        };

        getProducts();
    }, [slug]);

    const handlePageClick = ({ selected }) => {
        console.log(`Page clicked: ${selected}`);
        setCurrentPage(selected);
    };

    const getPagedData = () => {
        const offset = currentPage * itemsPerPage;
        const currentItems = productData.slice(offset, offset + itemsPerPage);
        console.log('Current items for page:', currentItems);
        return currentItems;
    };

    const currentItems = getPagedData();

    return (
        <div>
            <header className="header-image-container">
                <img src={Header} alt="Header" className="img-fluid" />
            </header>
            <div className="row mt-4 mx-0">
                <h1 className="h4 heading">Subcategories</h1>
            </div>
            <div className="row mt-4 mx-0">
                <div className="col-md-2">
                    <FilterSidebar />
                </div>
                <div className="col-md-10">
                    <div className="category-card-container">
                        {currentItems.map((product, index) => (
                            <ProductCard key={index} product={product} />
                        ))}
                    </div>
                    <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={Math.ceil(productData.length / itemsPerPage)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        disabledClassName={"disabled"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                    />
                </div>
            </div>
        </div>
    );
};

export default SubCategoryList;
