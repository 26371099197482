import { configureStore } from '@reduxjs/toolkit';
import checkoutReducer from '../slices/checkoutSlice';
import cartReducer from '../slices/CartSlice';
import productReducer from '../slices/ProductSlice';
import authReducer from '../slices/authSlice'; 
import contactSlice from '../slices/contactSlice';
import wishlistSlice from '../slices/wishlistSlice';

const store = configureStore({
  reducer: {
    checkout: checkoutReducer,
    cart: cartReducer,
    product: productReducer,
    auth: authReducer, 
    contact: contactSlice,
    wishlist: wishlistSlice,
  },
});

export default store;
