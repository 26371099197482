import React, { useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './FilterSidebar.css'; // Import the CSS file for custom styles

const FilterSidebar = () => {
  const [showSlider, setShowSlider] = useState(false);
  const [priceRange, setPriceRange] = useState([0, 100]);

  const handleSliderChange = (value) => {
    setPriceRange(value);
  };

  const handleApply = () => {
    setShowSlider(false);
  };

  return (
    <div className="filter">
    <div className="filter-sidebar p-4 bg-light border rounded">
      <h5 className="text-center">Categories</h5>
      <Form>
        <div className="ml-3">
          <Form.Check type="radio" name="category" id="category1" label="Frocks" className="my-2" />
          <Form.Check type="radio" name="category" id="category2" label="Blouses" className="my-2" />
          <Form.Check type="radio" name="category" id="category3" label="Shirts" className="my-2" />
        </div>

        <hr />

        <h5 className="text-center">Price Range</h5>
        <div className="d-flex justify-content-between price-range-buttons">
          <Button variant="outline-primary" size="sm" onClick={() => setShowSlider(true)}>
            Min
          </Button>
          <Button variant="outline-primary" size="sm" onClick={() => setShowSlider(true)}>
            Max
          </Button>
          <Button variant="dark" size="sm" onClick={() => setShowSlider(true)}>
            Apply
          </Button>
        </div>

        <Modal show={showSlider} onHide={() => setShowSlider(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Set Price Range</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Slider
              range
              allowCross={false}
              defaultValue={[0, 100]}
              min={0}
              max={1000}
              value={priceRange}
              onChange={handleSliderChange}
            />
            <div className="mt-2 text-center">
              <span>Min: Rs.{priceRange[0]}</span> - <span>Max: Rs.{priceRange[1]}</span>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowSlider(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={handleApply}>
              Apply
            </Button>
          </Modal.Footer>
        </Modal>

        <hr />

        <h5 className="text-center">Size</h5>
        <div className="ml-3">
          <Form.Check type="radio" name="size" id="size1" label="XS" className="my-2" />
          <Form.Check type="radio" name="size" id="size2" label="S" className="my-2" />
          <Form.Check type="radio" name="size" id="size3" label="M" className="my-2" />
          <Form.Check type="radio" name="size" id="size4" label="L" className="my-2" />
          <Form.Check type="radio" name="size" id="size5" label="XL" className="my-2" />
          <Form.Check type="radio" name="size" id="size6" label="2XL" className="my-2" />
        </div>

        <hr />

        <h5 className="text-center">Availability</h5>
        <div className="ml-3">
          <Form.Check type="radio" name="availability" id="availability1" label="In Stock" className="my-2" />
          <Form.Check type="radio" name="availability" id="availability2" label="Out of Stock" className="my-2" />
        </div>
      </Form>
    </div>
    </div>
  );
};

export default FilterSidebar;
