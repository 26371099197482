import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import ProductCard from '../../components/common/ProductCardComponent/Index';
import Header from '../../assests/Header.png';
import FilterSidebar from '../../components/FilterSidebar/index';
import ReactPaginate from 'react-paginate';
import { fetchProductData } from '../../Services/ProductServices';
import '../../pages/ProductsList/ProductsList.css';

const SearchResult = () => {
  const [allProducts, setAllProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const itemsPerPage = 12;
  const location = useLocation();
  const query = new URLSearchParams(location.search).get('query') || '';


  const fetchData = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const productsData = await fetchProductData(query);
      setAllProducts(productsData);
    } catch (error) {
      setError('Failed to fetch products. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, [query]);

  const filterProducts = useCallback(() => {
    const searchLower = query.toLowerCase();
    const filtered = allProducts.filter(product => {
      const productTitle = (product?.Title || '').toLowerCase();
      const productDescription = (product?.Description || '').toLowerCase();
      const productCategory = (product?.Category || '').toLowerCase();
      return (
        productTitle.includes(searchLower) ||
        productDescription.includes(searchLower) ||
        productCategory.includes(searchLower)
      );
    });
    setFilteredProducts(filtered);
    setCurrentPage(0);
  }, [query, allProducts]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (allProducts.length > 0) {
      filterProducts();
    }
  }, [query, allProducts, filterProducts]);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const getPagedData = () => {
    const offset = currentPage * itemsPerPage;
    return filteredProducts.slice(offset, offset + itemsPerPage);
  };

  const currentItems = getPagedData();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <header className="header-image-container">
        <img src={Header} alt="Header" className="img-fluid" />
      </header>
      <div className="row mt-4 mx-0">
        <h1 className="h4 heading">Search Results for "{query}"</h1>
        <p className="paragraph">Found {filteredProducts.length} matching products</p>
        <div className="row">
          <div className="col-lg-3">
            <FilterSidebar
              allProducts={allProducts}
              setFilteredProducts={products => {
                setFilteredProducts(products);
                setCurrentPage(0);
              }}
            />
          </div>
          <div className="col-lg-9">
            <div className="row">
              {currentItems.length === 0 ? (
                <p>No products found matching your search.</p>
              ) : (
                currentItems.map(product => (
                  <div className="col-lg-4 col-md-6 mb-4" key={product.ProductId}>
                    <ProductCard product={product} />
                  </div>
                ))
              )}
            </div>
            {filteredProducts.length > itemsPerPage && (
              <div className="pagination-container">
                <ReactPaginate
                  previousLabel="< Previous"
                  nextLabel="Next >"
                  breakLabel="..."
                  pageCount={Math.ceil(filteredProducts.length / itemsPerPage)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName="pagination"
                  activeClassName="active"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchResult;