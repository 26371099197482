import React, { useState, useEffect } from 'react';
import './Carousel.css';
import { Image_URL } from '../../../config/config';

const VerticalCarousel = ({ images, setMainImage }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const goToNextImage = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setCurrentImageIndex((prevIndex) => 
      (prevIndex + 1) % images.length
    );
  };

  const goToPrevImage = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setCurrentImageIndex((prevIndex) => 
      (prevIndex - 1 + images.length) % images.length
    );
  };

  const handleImageClick = (e, image) => {
    e.preventDefault();
    e.stopPropagation();
    setMainImage(image);
  };

 

  const renderImages = () => {
    const start = currentImageIndex;
    const end = (start + 3) % images.length;
    const displayedImages = [];

    for (let i = 0; i < 3; i++) {
      const index = (start + i) % images.length;
      displayedImages.push(images[index]);
    }

    return displayedImages.map((image, index) => (
      <img
        key={index}
        src={`${Image_URL}products/thumbnails/${image}`}
        alt={`Thumbnail ${index + 1}`}
        onClick={(e) => handleImageClick(e, image)}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = "https://jbadmin.alephaz.com/assets/img/placeholder-image.jpg";
        }}
      />
    ));
  };

  return (
    <div className="vertical-carousel" onClick={(e) => e.stopPropagation()}>
      <button className="carousel-button prev normal-mode" onClick={goToNextImage}>
        <i className="fa-solid fa-chevron-up"></i>
      </button>
      <button className="carousel-button prev responsive-mode" onClick={goToNextImage}>
        <i className="fa-solid fa-chevron-left"></i>
      </button>
      <div className="carousel-image-container">
        {renderImages()}
      </div>
      <button className="carousel-button next responsive-mode" onClick={goToPrevImage}>
        <i className="fa-solid fa-chevron-right"></i>
      </button>
      <button className="carousel-button next normal-mode" onClick={goToPrevImage}>
        <i className="fa-solid fa-chevron-down"></i>
      </button>
    </div>
  );
};

export default VerticalCarousel;
