import React from "react";
import { useNavigate } from "react-router-dom";
import "./OrderConfirmation.css";
import { ReactComponent as CheckmarkIcon } from "../../assests/checkmark.svg";

function OrderConfirmationPage() {
  const navigate = useNavigate();

  const handleContinueShopping = () => {
    navigate("/home");
  };

  return (
    <div className="order-confirmation">
      <div className="confirmation-content">
        <h1>Your Order Confirmed!</h1>
        <div className="confirmation-icon">
          <CheckmarkIcon className="checkmark" />
        </div>
        <p>
          Model Height 5' 6" wearing size M. Please bear in mind that the photo
          may be slightly different from the actual item in terms of colour due
          to lighting conditions or the display used to view.
        </p>
        <button
          className="continue-shopping-btn"
          onClick={handleContinueShopping}
        >
          Continue Shopping
        </button>
      </div>
    </div>
  );
}

export default OrderConfirmationPage;
