import React, { useEffect, useState } from "react";
import HorizontalCarousel from "../../components/common/productcarouselComponent/Index.jsx";
import "./Home.css";
import ProductBanner from "../../components/Home/ProductShowcase/ProductShowcase.jsx";
import ReviewCard from "../../components/Home/Testimonials/Index.jsx";
import BannerComponent from "../../components/common/BannerComponent/Index.jsx";
import Header from "../../assests/Header.png";
import { fetchProducts } from "../../slices/ProductSlice"; // Import the fetchProducts action from the slice
import { useDispatch, useSelector } from "react-redux";

function Home() {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.product.products);
  const reviews = [
    {
      name: "Darshika C",
      time: "A month ago",
      text: "The dresses at Jewelbox are very good quality, from the material to the color. With exceptional designs as well. Thank you Anusha for arranging to send on time.",
      avatar: "https://via.placeholder.com/50", // Placeholder URL for avatar images
    },
    // other reviews
  ];

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  return (
    <>
      <header className="header-image-container">
        <img src={Header} alt="Header" className="img-fluid" />
      </header>
      <div className="container">
        <h1 className="text-start">New Arrivals</h1>
        <HorizontalCarousel products={products} />
        <ProductBanner />
        <h1 className="text-start">Best Selling</h1>
        <HorizontalCarousel products={products} />
        <h1 className="text-start">Testimonials</h1>
        <div className="row">
          {reviews.map((review, index) => (
            <div className="col-md-4" key={index}>
              <ReviewCard review={review} />
            </div>
          ))}
        </div>
      </div>
      <BannerComponent />
    </>
  );
}

export default Home;
