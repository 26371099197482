import React from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./Tabs.css";

const DynamicTabs = ({ product }) => {
  // Parse KeyFeatures JSON
  const keyFeatures = JSON.parse(product.KeyFeatures || "[]");

  // Generate HTML for KeyFeatures
  const keyFeaturesHTML = keyFeatures.map((feature) => {
    return `
      <div>
        <h4>${feature.feature}</h4>
        <ul>
          ${feature.points.map((point) => `<li>${point}</li>`).join('')}
        </ul>
      </div>
    `;
  }).join('');

  // Split KeyPoints string into an array of points
  const keyPointsArray = product.KeyPoints
    ? product.KeyPoints.split(/[,\.]/).map(point => point.trim()).filter(point => point)
    : [];

  return (
    <Tabs>
      <TabList>
        <Tab>Description</Tab>
        <Tab>Specifications</Tab>
        <Tab>Ratings and Reviews</Tab>
      </TabList>

      <TabPanel>
        <ul>
          {keyPointsArray.map((point, index) => (
            <li key={index}>{point}</li>
          ))}
        </ul>
      </TabPanel>
      
      <TabPanel>
        <div dangerouslySetInnerHTML={{ __html: keyFeaturesHTML }}></div>
      </TabPanel>
      
      <TabPanel>
        <p>Ratings and Reviews content goes here...</p>
      </TabPanel>
    </Tabs>
  );
};

export default DynamicTabs;
