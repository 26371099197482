import axios from 'axios';
import { API_BASE_URL, SEARCH_URL } from '../config/config';

export const fetchRecentProducts = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/user/products/get-recent/`);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching recent product data:', error);
    throw error;
  }
};

export const fetchProductBySlug = async (slug) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/user/products/get-product-by-slug/?slug=${slug}`);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching product by slug:', error);
    throw error;
  }
};

export const fetchProductData = async (query) => {
  try {
    // If query is undefined or empty, fetch all products
    const url = query ? `${SEARCH_URL}?kw=${encodeURIComponent(query)}` : `${API_BASE_URL}/user/products/search/`;
    const response = await axios.get(url);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching product data:', error);
    throw error;
  }
};
export const fetchOrderDetails = async (userId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/user/order/all/?id=${userId}`);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching order details:', error);
    throw error;
  }
};