import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OrderModal from "../../components/common/modalComponent/OrderModal/OrderModal";
import {
  validateName,
  validateEmail,
  validatePassword,
  validateNumber,
  validateAddress,
  validatePostcode,
  validateProfileForm
} from "../../components/common/Validation";
import {
  setPasswordResetError,
  setPasswordResetSuccess,
} from "../../slices/authSlice";
import {
  RESET_PASSWORD_URL,
  PROFILE_EDIT_URL,
  BILLING_UPDATE_URL,
  SHIPPING_UPDATE_URL,
  Image_URL,
} from "../../config/config";
import { fetchOrdersByUserId } from "../../Services/profileServices";
import "./Profile.css";

const UserProfile = () => {
  const dispatch = useDispatch();
  const { userId: reduxUserId } = useSelector((state) => state.auth);

  const [profile, setProfile] = useState({
    firstName: "",
    lastName: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    postcode: "",
    email: "",
    contactNumber: "",
  });

  const [billingInfo, setBillingInfo] = useState({
    firstName: "",
    lastName: "",
    address1: "",
    address2: "",
    city: "",
    country: "",
    province: "",
    postalCode: "",
    phone: "",
    email: "",
  });

  const [shippingInfo, setShippingInfo] = useState({
    firstName: "",
    lastName: "",
    address1: "",
    address2: "",
    city: "",
    country: "",
    province: "",
    postalCode: "",
    phone: "",
    email: "",
  });

  const [passwords, setPasswords] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const [activeTab, setActiveTab] = useState("Profile");
  const [showModal, setShowModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [userId, setUserId] = useState(123);
  const [orderHistory, setOrderHistory] = useState([]);
  const [errors, setErrors] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [isBillingLoading, setIsBillingLoading] = useState(false);
  const [isShippingLoading, setIsShippingLoading] = useState(false);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const ordersPerPage = 3;

  useEffect(() => {
    const tokenData = JSON.parse(localStorage.getItem("tokenData"));
    if (tokenData?.accessToken) {
      try {
        const decodedToken = jwtDecode(tokenData.accessToken);
        setUserId(decodedToken.data.userId);
      } catch (error) {
        console.error("Error decoding token:", error);
        setUserId(tokenData.userId || reduxUserId);
      }
    }
  }, [reduxUserId]);

  useEffect(() => {
    if (userId) {
      console.log("Fetching order details for user ID:", userId);
      fetchOrdersByUserId(userId)
        .then((data) => {
          console.log("Fetched Order Data:", data);
          setOrderHistory(data);
        })
        .catch((error) => {
          console.error("Error fetching order details:", error);
        });
    }
  }, [userId]);

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {
      currentPassword: validatePassword(passwords.currentPassword),
      newPassword: validatePassword(passwords.newPassword),
      confirmNewPassword:
        passwords.newPassword !== passwords.confirmNewPassword
          ? "New password and confirm new password do not match."
          : "",
    };
    setErrors(newErrors);

    if (Object.values(newErrors).every((error) => error === "")) {
      try {
        const tokenData = JSON.parse(localStorage.getItem("tokenData"));
        const token = tokenData?.accessToken;
        const response = await fetch(RESET_PASSWORD_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            currentPassword: passwords.currentPassword,
            newPassword: passwords.newPassword,
            confirmPassword: passwords.confirmNewPassword,
            userId: userId,
          }),
        });
        const data = await response.json();
        if (response.ok && data.status === 1) {
          dispatch(setPasswordResetSuccess("Password reset successful!"));
          dispatch(setPasswordResetError(""));
          setPasswords({
            currentPassword: "",
            newPassword: "",
            confirmNewPassword: "",
          });
          toast.success("Password reset successful!");
        } else {
          dispatch(setPasswordResetError(data.message || "Password reset failed"));
          dispatch(setPasswordResetSuccess(""));
          toast.error(data.message || "Password reset failed");
        }
      } catch (error) {
        console.error("Error resetting password:", error);
        dispatch(setPasswordResetError("An error occurred. Please try again."));
        dispatch(setPasswordResetSuccess(""));
        toast.error("An error occurred. Please try again.");
      }
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleOrderDetails = (order) => {
    setSelectedOrder(order);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const getHeading = () => {
    switch (activeTab) {
      case "Profile":
        return "User Profile";
      case "Order History":
        return "Order History";
      case "Settings":
        return "Settings";
      case "Billing Info":
        return "Billing Info";
      case "Shipping Info":
        return "Shipping Info";
      default:
        return "User Profile";
    }
  };

  const handleProfileSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateProfileForm(profile);
    setErrors(newErrors);

    if (Object.values(newErrors).every((error) => error === "")) {
      setIsLoading(true);
      try {
        const tokenData = JSON.parse(localStorage.getItem("tokenData"));
        const token = tokenData?.accessToken;
        const payload = {
          userId: userId,
          fName: profile.firstName,
          lName: profile.lastName,
          email: profile.email,
          status: "1",
          name: `${profile.firstName} ${profile.lastName}`
        };
        const response = await fetch(PROFILE_EDIT_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        });
        if (response.ok) {
          const data = await response.json();
          console.log("Profile updated successfully", data);
          toast.success("Profile updated successfully!");
        } else {
          const errorData = await response.json();
          console.error("Error updating profile:", errorData.message);
          toast.error("Error updating profile. Please try again.");
        }
      } catch (error) {
        console.error("Error updating profile:", error);
        toast.error("An error occurred. Please try again.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswords((prevPasswords) => ({
      ...prevPasswords,
      [name]: value,
    }));
  };

  const handleBillingInfoChange = (e) => {
    const { name, value } = e.target;
    setBillingInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const handleBillingSubmit = async (e) => {
    e.preventDefault();
    setIsBillingLoading(true);
    try {
      const tokenData = JSON.parse(localStorage.getItem("tokenData"));
      const token = tokenData?.accessToken;
      const payload = {
        id: "1",
        userId: userId,
        fName: billingInfo.firstName,
        lName: billingInfo.lastName,
        address1: billingInfo.address1,
        address2: billingInfo.address2,
        city: billingInfo.city,
        country: billingInfo.country,
        province: billingInfo.province,
        postalCode: billingInfo.postalCode,
        phone: billingInfo.phone,
        email: billingInfo.email,
      };
      const response = await fetch(BILLING_UPDATE_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
      if (response.ok) {
        const data = await response.json();
        console.log("Billing information updated successfully", data);
        toast.success("Billing information updated successfully!");
      } else {
        const errorData = await response.json();
        console.error("Error updating billing information:", errorData.message);
        toast.error("Error updating billing information. Please try again.");
      }
    } catch (error) {
      console.error("Error updating billing information:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setIsBillingLoading(false);
    }
  };

  const handleShippingInfoChange = (e) => {
    const { name, value } = e.target;
    setShippingInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const handleShippingSubmit = async (e) => {
    e.preventDefault();
    setIsShippingLoading(true);
    try {
      const tokenData = JSON.parse(localStorage.getItem("tokenData"));
      const token = tokenData?.accessToken;
      const payload = {
        id: "2",
        userId: userId,
        fName: shippingInfo.firstName,
        lName: shippingInfo.lastName,
        address1: shippingInfo.address1,
        address2: shippingInfo.address2,
        city: shippingInfo.city,
        country: shippingInfo.country,
        province: shippingInfo.province,
        postalCode: shippingInfo.postalCode,
        phone: shippingInfo.phone,
        email: shippingInfo.email,
      };
      const response = await fetch(SHIPPING_UPDATE_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
      if (response.ok) {
        const data = await response.json();
        console.log("Shipping information updated successfully", data);
        toast.success("Shipping information updated successfully!");
      } else {
        const errorData = await response.json();
        console.error("Error updating shipping information:", errorData.message);
        toast.error("Error updating shipping information. Please try again.");
      }
    } catch (error) {
      console.error("Error updating shipping information:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setIsShippingLoading(false);
    }
  };

  const getStatusClass = (status) => {
    switch (status) {
      case "0": return "in-progress";
      case "1": return "delivered";
      default: return "";
    }
  };
  
  const getStatusText = (status) => {
    switch (status) {
      case "0": return "In Progress";
      case "1": return "Delivered";
      default: return "Unknown";
    }
  };
  
  const getCategoryName = (categoryId) => {
    const categoryMap = {
      "8": "Women",
      "9": "Men",
      // Add more mappings as needed
    };
    return categoryMap[categoryId] || "Unknown";
  };
  // Pagination logic
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = orderHistory.slice(indexOfFirstOrder, indexOfLastOrder);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="user-profile">
      <h1>{getHeading()}</h1>
      <div className="profile-container">
        <div className="sidebar-container">
          <div className="sidebar">
            <ul>
              <li
                className={activeTab === "Profile" ? "active" : ""}
                onClick={() => handleTabClick("Profile")}
              >
                <i className="fa-solid fa-user"></i> Profile
              </li>
              <li
                className={activeTab === "Order History" ? "active" : ""}
                onClick={() => handleTabClick("Order History")}
              >
                <i className="fa-solid fa-store"></i> Order History
              </li>
              <li
                className={activeTab === "Settings" ? "active" : ""}
                onClick={() => handleTabClick("Settings")}
              >
                <i className="fa-solid fa-gear"></i> Settings
              </li>
              <li
                className={activeTab === "Billing Info" ? "active" : ""}
                onClick={() => handleTabClick("Billing Info")}
              >
                <i className="fa-solid fa-file-invoice-dollar"></i> Billing Info
              </li>
              <li
                className={activeTab === "Shipping Info" ? "active" : ""}
                onClick={() => handleTabClick("Shipping Info")}
              >
                <i className="fa-solid fa-truck"></i> Shipping Info
              </li>
            </ul>
          </div>
        </div>
        <div className="personal-info-container">
          {activeTab === "Profile" && (
            <div className="profile-form">
              <h2>Personal Information</h2>
              <form onSubmit={handleProfileSubmit}>
                <div className="form-row">
                  <div className="form-group">
                    <label>First Name</label>
                    <input
                      type="text"
                      name="firstName"
                      placeholder="Kevin"
                      value={profile.firstName}
                      onChange={handleProfileChange}
                    />
                    {errors.firstName && (
                      <p className="error">{errors.firstName}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <label>Last Name</label>
                    <input
                      type="text"
                      name="lastName"
                      placeholder="Paul"
                      value={profile.lastName}
                      onChange={handleProfileChange}
                    />
                    {errors.lastName && (
                      <p className="error">{errors.lastName}</p>
                    )}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                  <label>Address Line 1</label>
                    <input
                      type="text"
                      name="addressLine1"
                      placeholder="32/A , Sea View, Galle Road"
                      value={profile.addressLine1}
                      onChange={handleProfileChange}
                    />
                    {errors.addressLine1 && (
                      <p className="error">{errors.addressLine1}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <label>Address Line 2</label>
                    <input
                      type="text"
                      name="addressLine2"
                      placeholder="Colombo 03"
                      value={profile.addressLine2}
                      onChange={handleProfileChange}
                    />
                    {errors.addressLine2 && (
                      <p className="error">{errors.addressLine2}</p>
                    )}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label>City</label>
                    <input
                      type="text"
                      name="city"
                      placeholder="Kollupitiya"
                      value={profile.city}
                      onChange={handleProfileChange}
                    />
                    {errors.city && <p className="error">{errors.city}</p>}
                  </div>
                  <div className="form-group">
                    <label>Postcode</label>
                    <input
                      type="text"
                      name="postcode"
                      placeholder="10160"
                      value={profile.postcode}
                      onChange={handleProfileChange}
                    />
                    {errors.postcode && (
                      <p className="error">{errors.postcode}</p>
                    )}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label>Email Address</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="kevin@gmail.com"
                      value={profile.email}
                      onChange={handleProfileChange}
                    />
                    {errors.email && <p className="error">{errors.email}</p>}
                  </div>
                  <div className="form-group">
                    <label>Contact Number</label>
                    <input
                      type="text"
                      name="contactNumber"
                      placeholder="0767893678"
                      value={profile.contactNumber}
                      onChange={handleProfileChange}
                    />
                    {errors.contactNumber && (
                      <p className="error">{errors.contactNumber}</p>
                    )}
                  </div>
                </div>
                <button type="submit" className="save-button" disabled={isLoading}>
                  {isLoading ? "Saving..." : "Save Changes"}
                </button>
              </form>
            </div>
          )}
          {activeTab === "Order History" && (
            <div className="order-history">
              <h2>Order History</h2>
              <table>
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>SKU</th>
                    <th>Status</th>
                    <th>Price</th>
                    <th>Category</th>
                    <th>Order Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {currentOrders.map((order) => {
                    const orderDetail = order.OrderDetails && order.OrderDetails[0] ? order.OrderDetails[0] : null;
                    return (
                      <tr key={order.OrderId}>
                        <td>
                          <div className="product-cell">
                            {orderDetail && (
                              <img
                                src={`${Image_URL}products/thumbnails/${orderDetail.Sources?.split(',')[0]}`}
                                alt="Product"
                                className="product-image"
                              />
                            )}
                            {orderDetail && (
                              <span>
                                {orderDetail.Title}
                                <br />
                                #{orderDetail.ProductId}
                              </span>
                            )}
                          </div>
                        </td>
                        <td>{orderDetail ? orderDetail.Slug : 'N/A'}</td>
                        <td>
                          <span className={`status ${getStatusClass(order.OrderStatus)}`}>
                            {getStatusText(order.OrderStatus)}
                          </span>
                        </td>
                        <td>Rs. {order.OrderAmount}</td>
                        <td>{orderDetail ? getCategoryName(orderDetail.CategoryId) : 'Unknown'}</td>
                        <td>{orderDetail ? new Date(orderDetail.DateCreated).toLocaleString() : 'N/A'}</td>
                        <td>
                          <button
                            className="more-info"
                            onClick={() => handleOrderDetails(order)}
                          >
                            ⋮
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="pagination">
                <button
                  className="prev"
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  ← Previous
                </button>
                <span className="page-numbers">
                  {[...Array(Math.ceil(orderHistory.length / ordersPerPage)).keys()].map((number) => (
                    <span
                      key={number + 1}
                      className={currentPage === number + 1 ? "active" : ""}
                      onClick={() => paginate(number + 1)}
                    >
                      {number + 1}
                    </span>
                  ))}
                </span>
                <button
                  className="next"
                  onClick={() => paginate(currentPage + 1)}
                  disabled={currentPage === Math.ceil(orderHistory.length / ordersPerPage)}
                >
                  Next →
                </button>
              </div>
            </div>
          )}
          {activeTab === "Settings" && (
            <div className="settings-form">
              <h2>Change Password</h2>
              <form onSubmit={handlePasswordSubmit}>
                <div className="form-group">
                  <label>Current Password</label>
                  <input
                    type="password"
                    name="currentPassword"
                    placeholder="****"
                    value={passwords.currentPassword}
                    onChange={handlePasswordChange}
                  />
                  {errors.currentPassword && (
                    <p className="error">{errors.currentPassword}</p>
                  )}
                </div>
                <div className="form-group">
                  <label>New Password</label>
                  <input
                    type="password"
                    name="newPassword"
                    placeholder="****"
                    value={passwords.newPassword}
                    onChange={handlePasswordChange}
                  />
                  {errors.newPassword && (
                    <p className="error">{errors.newPassword}</p>
                  )}
                </div>
                <div className="form-group">
                  <label>Confirm New Password</label>
                  <input
                    type="password"
                    name="confirmNewPassword"
                    placeholder="****"
                    value={passwords.confirmNewPassword}
                    onChange={handlePasswordChange}
                  />
                  {errors.confirmNewPassword && (
                    <p className="error">{errors.confirmNewPassword}</p>
                  )}
                </div>
                <button type="submit" className="save-button">
                  Change Password
                </button>
              </form>
            </div>
          )}
          {activeTab === "Billing Info" && (
            <div className="billing-info">
              <h2>Billing Information</h2>
              <BillingShippingForm
                formType="billing"
                formData={billingInfo}
                handleInputChange={handleBillingInfoChange}
                handleSubmit={handleBillingSubmit}
                isLoading={isBillingLoading}
              />
            </div>
          )}
          {activeTab === "Shipping Info" && (
            <div className="shipping-info">
              <h2>Shipping Information</h2>
              <BillingShippingForm
                formType="shipping"
                formData={shippingInfo}
                handleInputChange={handleShippingInfoChange}
                handleSubmit={handleShippingSubmit}
                isLoading={isShippingLoading}
              />
            </div>
          )}
        </div>
      </div>
      {showModal && selectedOrder && (
        <OrderModal
          order={selectedOrder}
          onClose={closeModal}
        />
      )}
      <ToastContainer />
    </div>
  );
};

const BillingShippingForm = ({ formType, formData, handleInputChange, handleSubmit, isLoading }) => {
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {
      firstName: validateName(formData.firstName),
      lastName: validateName(formData.lastName),
      address1: validateAddress(formData.address1),
      city: validateName(formData.city),
      country: validateName(formData.country),
      province: validateName(formData.province),
      postalCode: validatePostcode(formData.postalCode),
      phone: validateNumber(formData.phone),
      email: validateEmail(formData.email),
    };
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      handleSubmit(e);
    }
  };

  return (
    <form onSubmit={onSubmit} className="billing-shipping-form">
      <div className="form-row">
        <div className="form-group">
          <label htmlFor="firstName">First name</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
            placeholder="First name"
            required
          />
          {errors.firstName && <p className="error">{errors.firstName}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="lastName">Last name</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
            placeholder="Last name"
            required
          />
          {errors.lastName && <p className="error">{errors.lastName}</p>}
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="address1">Address Line 1</label>
        <input
          type="text"
          id="address1"
          name="address1"
          value={formData.address1}
          onChange={handleInputChange}
          placeholder="Address Line 1"
          required
        />
        {errors.address1 && <p className="error">{errors.address1}</p>}
      </div>
      <div className="form-group">
        <label htmlFor="address2">Address Line 2 (optional)</label>
        <input
          type="text"
          id="address2"
          name="address2"
          value={formData.address2}
          onChange={handleInputChange}
          placeholder="Address Line 2 (optional)"
        />
      </div>
      <div className="form-group">
        <label htmlFor="city">City</label>
        <input
          type="text"
          id="city"
          name="city"
          value={formData.city}
          onChange={handleInputChange}
          placeholder="City"
          required
        />
        {errors.city && <p className="error">{errors.city}</p>}
      </div>
      <div className="form-row">
        <div className="form-group">
          <label htmlFor="country">Country</label>
          <input
            type="text"
            id="country"
            name="country"
            value={formData.country}
            onChange={handleInputChange}
            placeholder="Country"
            required
          />
          {errors.country && <p className="error">{errors.country}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="province">Province</label>
          <input
            type="text"
            id="province"
            name="province"
            value={formData.province}
            onChange={handleInputChange}
            placeholder="Province"
            required
          />
          {errors.province && <p className="error">{errors.province}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="postalCode">Postal Code</label>
          <input
            type="text"
            id="postalCode"
            name="postalCode"
            value={formData.postalCode}
            onChange={handleInputChange}
            placeholder="Postal Code"
            required
          />
          {errors.postalCode && <p className="error">{errors.postalCode}</p>}
        </div>
      </div>
      <div className="form-row">
        <div className="form-group">
          <label htmlFor="phone">Phone</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            placeholder="Phone"
            required
          />
          {errors.phone && <p className="error">{errors.phone}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            placeholder="Email"
            required
          />
          {errors.email && <p className="error">{errors.email}</p>}
        </div>
      </div>
      <button type="submit" className="save-button" disabled={isLoading}>
        {isLoading ? "Updating..." : `Update ${formType === "billing" ? "Billing" : "Shipping"} Information`}
      </button>
    </form>
  );
};

export default UserProfile;