import React, { useEffect, useState } from 'react';
import ProductCard from '../../components/common/ProductCardComponent/Index';
import { fetchProductData } from '../../Services/ProductServices';
import Spinner from '../../components/common/loadingSpinnercomponent/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../../slices/CartSlice';
import "./Wishlist.css"

const WishlistPage = () => {
  const [wishlistProducts, setWishlistProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const wishlistProductIds = useSelector(state => state.wishlist.wishlistProductIds);

  useEffect(() => {
    const loadWishlistProducts = async () => {
      try {
        const products = await fetchProductData();
        const wishlistProductIds = JSON.parse(localStorage.getItem('wishlistProductIds')) || [];
        const filteredProducts = products.filter(product => wishlistProductIds.includes(product.ProductId));
        setWishlistProducts(filteredProducts);
      } catch (error) {
        setError(error);
        console.log('error fetching wishlist products:', error);
      } finally {
        setLoading(false);
      }
    };

    loadWishlistProducts();
  }, []);

  const handleAddToCart = () => {
    wishlistProducts.forEach(product => {
      dispatch(addToCart({
        ProductId: product.ProductId,
        Title: product.Title,
        Price: product.Price,
        Color: product.Color,
        Size: product.Size,
        quantity: 1,
        Image: product.Image,
      }));
    });

    // Clear the wishlist from local storage
    localStorage.removeItem('wishlistProductIds');
    setWishlistProducts([]);
  };

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <div>Error loading wishlist products: {error.message}</div>;
  }

  return (
    <div className="wishlist-page">
      <h1 className='heading'>Wishlist</h1>
      <hr />
      <div className="wishlist-count-container">
        <span className="wishlist-count">{wishlistProducts.length} items</span>
      </div>
      {wishlistProducts.length === 0 ? (
        <p className="no-products">No products are available</p>
      ) : (
        <div className="wishlist-content">
          <div className="wishlist-grid">
            {wishlistProducts.map(product => (
              <ProductCard key={product.ProductId} product={product} />
            ))}
          </div>
          <div className="button-container">
            <button className="add-to-cart-button" onClick={handleAddToCart}>
              <i className="fa-solid fa-cart-shopping"></i>
              Add All to Cart
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default WishlistPage;
