import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import Login from './components/authentication/Login/Login';
import SignUp from './components/authentication/SignUp/SignUp';
import ForgotPassword from './components/authentication/ForgotPassword/ForgotPassword';
import ProductView from './components/product/Products/ProductView';
import ContactUs from './components/Contact/ContactUs';
import Checkout from './components/Checkout/Checkout';
import Cart from './components/product/Cart/Cart';
import ProductCard from './components/common/ProductCardComponent/Index';
import Home from './pages/Home/Home';
import ProductList from './pages/ProductsList/Index';
import ViewProduct from './pages/ViewProduct/Index';
import OrderConfirmationPage from './components/Checkout/OrderConfirmation';
import AboutUs from './pages/AboutUs/aboutUs';
import UserProfile from './pages/UserProfile/Profile';
import SearchResult from './pages/SearchResult/searchResult';
import Category from './pages/Categories/Index';
import SubCategoryList from './pages/subCategories/Index';
import Collection from './pages/Collections/Index';
import WishlistPage from './pages/Wishlist/Index';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout showNavbar={true} showFooter={true}><Home /></Layout>} />
        <Route path="/login" element={<Layout showNavbar={true} showFooter={false}><Login /></Layout>} />
        <Route path="/signup" element={<Layout showNavbar={true} showFooter={false}><SignUp /></Layout>} />
        <Route path="/forgot-password" element={<Layout showNavbar={true} showFooter={false}><ForgotPassword /></Layout>} />
        <Route path="/product-view" element={<Layout showNavbar={true} showFooter={false}><ProductView /></Layout>} />
        <Route path="/contact-us" element={<Layout showNavbar={true} showFooter={true}><ContactUs /></Layout>} />
        <Route path="/checkout" element={<Layout showNavbar={true} showFooter={false}><Checkout /></Layout>} />
        <Route path="/cart" element={<Layout showNavbar={true} showFooter={true}><Cart /></Layout>} />
        <Route path="/home" element={<Layout showNavbar={true} showFooter={true}><Home /></Layout>} />
        <Route path="/product-list" element={<Layout showNavbar={true} showFooter={true}><ProductList /></Layout>} />
        <Route path="/product-card" element={<Layout showNavbar={true} showFooter={true}><ProductCard /></Layout>} />
        <Route path="/product/:slug" element={<Layout showNavbar={true} showFooter={true}><ViewProduct /></Layout>} />
        <Route path="/order-confirmation" element={<Layout showNavbar={true} showFooter={true}><OrderConfirmationPage /></Layout>} /> 
        <Route path="/about-us" element={<Layout showNavbar={true} showFooter={false}><AboutUs/></Layout>} /> 
        <Route path="/profile" element={<Layout showNavbar={true} showFooter={true}><UserProfile/></Layout>} /> 
        <Route path="/search" element={<Layout showNavbar={true} showFooter={true}><SearchResult/></Layout>} /> 
        <Route path="/profile" element={<Layout showNavbar={true} showFooter={true}><UserProfile/></Layout>} />
        <Route path="/category/:slug" element={<Layout showNavbar={true} showFooter={true}><Category/></Layout>} />
        <Route path="/subcategory/:slug" element={<Layout showNavbar={true} showFooter={true}><SubCategoryList/></Layout>} />
        <Route path="/collection/:slug" element={<Layout showNavbar={true} showFooter={true}><Collection/></Layout>} />
        <Route path="/wishlist" element={<Layout showNavbar={true} showFooter={true}><WishlistPage/></Layout>} />
      </Routes>
    </Router>
  );
};

export default App;