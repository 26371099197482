import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode"; // Correct import statement
import "./Checkout.css";
import kokoLogo from "../../assests/koko.png";
import mintpayLogo from "../../assests/mintpay.png";
import { Image_URL } from "../../config/config";
import {
  validateName,
  validateEmail,
  validateNumber,
  validateAddress,
  validatePostcode,
} from "../common/Validation";
import { SAVE_ORDER_URL, Shipping_Details_URL } from "../../config/config";

function CheckoutPage() {
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState([]);
  const [checkoutState, setCheckoutState] = useState({
    billingAddress: {
      firstName: '',
      lastName: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      postcode: '',
      email: '',
      contactNumber: '',
    },
    shippingAddress: {
      firstName: '',
      lastName: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      postcode: '',
      email: '',
      contactNumber: '',
    },
    selectedDeliveryOption: '',
    useShippingAsBilling: false,
    discount: 0,
    subtotal: 0,
    shipping: 350,
    total: 0
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    postcode: "",
    email: "",
    contactNumber: "",
  });

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("Payhere");
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(null);

  useEffect(() => {
    const savedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    setCartItems(savedCartItems);

    const subtotal = savedCartItems.reduce((total, item) => total + item.Price * item.quantity, 0);
    setCheckoutState(prevState => ({
      ...prevState,
      subtotal: subtotal,
      total: subtotal + prevState.shipping - prevState.discount,
    }));
  }, []);

  const handleInputChange = (e, addressType = "billing") => {
    const { name, value } = e.target;
    let errorMessage = "";

    switch (name) {
      case "firstName":
      case "lastName":
        errorMessage = validateName(value);
        break;
      case "addressLine1":
      case "addressLine2":
        errorMessage = validateAddress(value);
        break;
      case "city":
        errorMessage = validateName(value);
        break;
      case "postcode":
        errorMessage = validatePostcode(value);
        break;
      case "email":
        errorMessage = validateEmail(value);
        break;
      case "contactNumber":
        errorMessage = validateNumber(value);
        break;
      default:
        break;
    }

    setCheckoutState(prevState => {
      const newState = {
        ...prevState,
        [`${addressType}Address`]: {
          ...prevState[`${addressType}Address`],
          [name]: value,
        }
      };
      if (addressType === "billing" && prevState.useShippingAsBilling) {
        newState.shippingAddress = { ...newState.billingAddress };
      }
      return newState;
    });

    setErrors({ ...errors, [name]: errorMessage });
  };

  const handleCheckboxChange = () => {
    setCheckoutState(prevState => ({
      ...prevState,
      useShippingAsBilling: !prevState.useShippingAsBilling,
      shippingAddress: !prevState.useShippingAsBilling ? prevState.billingAddress : prevState.shippingAddress,
    }));
  };

  const handleDeliveryOptionChange = (option) => {
    setCheckoutState(prevState => ({
      ...prevState,
      selectedDeliveryOption: option,
    }));
  };

  const sendShippingDetails = async (shippingDetails) => {
    try {
      const tokenData = JSON.parse(localStorage.getItem("tokenData"));
      if (tokenData && tokenData.accessToken) {
        const decodedToken = jwtDecode(tokenData.accessToken);
        const userId = decodedToken.data.userId;

        const payload = {
          ...shippingDetails,
          userId: userId,
          email: checkoutState.shippingAddress.email, // Include email in the payload
          id: "2" 
        };

        console.log("Sending shipping details payload:", payload); // Log the payload

        const response = await axios.post(Shipping_Details_URL, payload);
        if (response.data.status === 1) {
          console.log("Shipping details sent successfully:", response.data);
        } else {
          setApiError(response.data.message);
        }
      } else {
        setApiError("User is not authenticated.");
      }
    } catch (error) {
      if (error.response) {
        console.error("Server responded with an error:", error.response.data);
        setApiError(
          error.response.data.message ||
          "An error occurred while sending shipping details. Please try again."
        );
      } else {
        console.error("Error calling shipping details API:", error);
        setApiError(
          "An error occurred while sending shipping details. Please try again."
        );
      }
    }
  };

  const saveOrder = async () => {
    setIsLoading(true);
    setApiError(null);

    const payload = {
      userId: 123,
      contactInfo: {
        email: checkoutState.billingAddress.email,
      },
      orderDetails: {
        carts: cartItems.map(item => ({
          productId: item.ProductId,
          qty: item.quantity,
          price: item.Price,
          colorId: item.ColorId,
          sizeId: item.SizeId,
          image: item.Image,
        })),
        couponId: "",
        total: checkoutState.total,
        tax: 0,
        discount: checkoutState.discount,
        shipping: checkoutState.shipping,
        orderStatus: "Pending",
        note: "",
      },
      paymentMethod: selectedPaymentMethod,
      shippingInfo: {
        fName: checkoutState.shippingAddress.firstName,
        lName: checkoutState.shippingAddress.lastName,
        address1: checkoutState.shippingAddress.addressLine1,
        address2: checkoutState.shippingAddress.addressLine2,
        city: checkoutState.shippingAddress.city,
        country: "Sri Lanka",
        province: "Western",
        postalCode: checkoutState.shippingAddress.postcode,
        phone: checkoutState.shippingAddress.contactNumber,
        email: checkoutState.shippingAddress.email, // Include email in the payload
        id: 1 // Add the id to the payload
      },
      billingInfo: {
        fName: checkoutState.billingAddress.firstName,
        lName: checkoutState.billingAddress.lastName,
        address1: checkoutState.billingAddress.addressLine1,
        address2: checkoutState.billingAddress.addressLine2,
        city: checkoutState.billingAddress.city,
        country: "Sri Lanka",
        province: "",
        postalCode: checkoutState.billingAddress.postcode,
        phone: checkoutState.billingAddress.contactNumber,
      },
    };

    try {
      const response = await axios.post(SAVE_ORDER_URL, payload);
      if (response.data.status === 1) {
        console.log("Order placed successfully:", response.data);
        // Send shipping details to the secondary API
        await sendShippingDetails(payload.shippingInfo);
        localStorage.removeItem('cartItems'); // Clear cart items from localStorage
        navigate("/order-confirmation");
      } else {
        setApiError(response.data.message);
      }
    } catch (error) {
      if (error.response) {
        console.error("Server responded with an error:", error.response.data);
        setApiError(
          error.response.data.message ||
          "An error occurred while placing your order. Please try again."
        );
      } else {
        console.error("Error calling API:", error);
        setApiError(
          "An error occurred while placing your order. Please try again."
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="checkout">
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <h1>
              <b>Checkout</b>
            </h1>
            <div className="billing-address-form">
              <h2>Billing Address</h2>
              <div className="form-row">
                <div className="form-group">
                  <label>First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    value={checkoutState.billingAddress.firstName}
                    onChange={(e) => handleInputChange(e, "billing")}
                    placeholder="Enter your first name"
                  />
                  {errors.firstName && (
                    <div className="error-message">{errors.firstName}</div>
                  )}
                </div>
                <div className="form-group">
                  <label>Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    value={checkoutState.billingAddress.lastName}
                    onChange={(e) => handleInputChange(e, "billing")}
                    placeholder="Enter your last name"
                  />
                  {errors.lastName && (
                    <div className="error-message">{errors.lastName}</div>
                  )}
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label>Address Line 1</label>
                  <input
                    type="text"
                    name="addressLine1"
                    value={checkoutState.billingAddress.addressLine1}
                    onChange={(e) => handleInputChange(e, "billing")}
                    placeholder="Enter address line 1"
                  />
                  {errors.addressLine1 && (
                    <div className="error-message">{errors.addressLine1}</div>
                  )}
                </div>
                <div className="form-group">
                  <label>Address Line 2</label>
                  <input
                    type="text"
                    name="addressLine2"
                    value={checkoutState.billingAddress.addressLine2}
                    onChange={(e) => handleInputChange(e, "billing")}
                    placeholder="Enter address line 2"
                  />
                  {errors.addressLine2 && (
                    <div className="error-message">{errors.addressLine2}</div>
                  )}
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label>City</label>
                  <input
                    type="text"
                    name="city"
                    value={checkoutState.billingAddress.city}
                    onChange={(e) => handleInputChange(e, "billing")}
                    placeholder="Enter your city"
                  />
                  {errors.city && (
                    <div className="error-message">{errors.city}</div>
                  )}
                </div>
                <div className="form-group">
                  <label>Postcode</label>
                  <input
                    type="text"
                    name="postcode"
                    value={checkoutState.billingAddress.postcode}
                    onChange={(e) => handleInputChange(e, "billing")}
                    placeholder="Enter your postcode"
                  />
                  {errors.postcode && (
                    <div className="error-message">{errors.postcode}</div>
                  )}
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label>Email Address</label>
                  <input
                    type="email"
                    name="email"
                    value={checkoutState.billingAddress.email}
                    onChange={(e) => handleInputChange(e, "billing")}
                    placeholder="Enter your email address"
                  />
                  {errors.email && (
                    <div className="error-message">{errors.email}</div>
                  )}
                </div>
                <div className="form-group">
                  <label>Contact Number</label>
                  <input
                    type="text"
                    name="contactNumber"
                    value={checkoutState.billingAddress.contactNumber}
                    onChange={(e) => handleInputChange(e, "billing")}
                    placeholder="Enter your contact number"
                  />
                  {errors.contactNumber && (
                    <div className="error-message">{errors.contactNumber}</div>
                  )}
                </div>
              </div>
              <div className="form-row checkbox-group">
                <label>
                  <input
                    type="checkbox"
                    checked={checkoutState.useShippingAsBilling}
                    onChange={handleCheckboxChange}
                  />
                  Use billing address as shipping address
                </label>
              </div>
              {!checkoutState.useShippingAsBilling && (
                <div className="shipping-address-form">
                  <h2>Shipping Address</h2>
                  <div className="form-row">
                    <div className="form-group">
                      <label>First Name</label>
                      <input
                        type="text"
                        name="firstName"
                        value={checkoutState.shippingAddress.firstName}
                        onChange={(e) => handleInputChange(e, "shipping")}
                        placeholder="Enter your first name"
                      />
                      {errors.firstName && (
                        <div className="error-message">{errors.firstName}</div>
                      )}
                    </div>
                    <div className="form-group">
                      <label>Last Name</label>
                      <input
                        type="text"
                        name="lastName"
                        value={checkoutState.shippingAddress.lastName}
                        onChange={(e) => handleInputChange(e, "shipping")}
                        placeholder="Enter your last name"
                      />
                      {errors.lastName && (
                        <div className="error-message">{errors.lastName}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group">
                      <label>Address Line 1</label>
                      <input
                        type="text"
                        name="addressLine1"
                        value={checkoutState.shippingAddress.addressLine1}
                        onChange={(e) => handleInputChange(e, "shipping")}
                        placeholder="Enter address line 1"
                      />
                      {errors.addressLine1 && (
                        <div className="error-message">
                          {errors.addressLine1}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label>Address Line 2</label>
                      <input
                        type="text"
                        name="addressLine2"
                        value={checkoutState.shippingAddress.addressLine2}
                        onChange={(e) => handleInputChange(e, "shipping")}
                        placeholder="Enter address line 2"
                      />
                      {errors.addressLine2 && (
                        <div className="error-message">
                          {errors.addressLine2}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group">
                      <label>City</label>
                      <input
                        type="text"
                        name="city"
                        value={checkoutState.shippingAddress.city}
                        onChange={(e) => handleInputChange(e, "shipping")}
                        placeholder="Enter your city"
                      />
                      {errors.city && (
                        <div className="error-message">{errors.city}</div>
                      )}
                    </div>
                    <div className="form-group">
                      <label>Postcode</label>
                      <input
                        type="text"
                        name="postcode"
                        value={checkoutState.shippingAddress.postcode}
                        onChange={(e) => handleInputChange(e, "shipping")}
                        placeholder="Enter your postcode"
                      />
                      {errors.postcode && (
                        <div className="error-message">{errors.postcode}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group">
                      <label>Email Address</label>
                      <input
                        type="email"
                        name="email"
                        value={checkoutState.shippingAddress.email}
                        onChange={(e) => handleInputChange(e, "shipping")}
                        placeholder="Enter your email address"
                      />
                      {errors.email && (
                        <div className="error-message">{errors.email}</div>
                      )}
                    </div>
                    <div className="form-group">
                      <label>Contact Number</label>
                      <input
                        type="text"
                        name="contactNumber"
                        value={checkoutState.shippingAddress.contactNumber}
                        onChange={(e) => handleInputChange(e, "shipping")}
                        placeholder="Enter your contact number"
                      />
                      {errors.contactNumber && (
                        <div className="error-message">
                          {errors.contactNumber}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <h2>Delivery options</h2>
              <div className="delivery-options">
                <button
                  className={`option ${
                    checkoutState.selectedDeliveryOption === "home"
                      ? "active"
                      : ""
                  }`}
                  onClick={() => handleDeliveryOptionChange("home")}
                >
                  Home delivery
                  <span className="option-subtext">
                    Takes 3-5 business days
                  </span>
                </button>
                <button
                  className={`option ${
                    checkoutState.selectedDeliveryOption === "store"
                      ? "active"
                      : ""
                  }`}
                  onClick={() => handleDeliveryOptionChange("store")}
                >
                  In-store pickup
                  <span className="option-subtext">
                    Pick from store location
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="order-summary">
              <h2>Order Summary</h2>
              {cartItems.map((item, index) => (
                <div className="order-item" key={index}>
                  <img src={Image_URL + 'products/thumbnails/' + item.Image} alt="Product" 
                    onError={(e) => { e.target.onerror = null; e.target.src='https://jbadmin.alephaz.com/assets/img/placeholder-image.jpg'; }}
                  />
                  <div>
                    <div className="product-name">{item.Title}</div>
                    <div className="product-price">Rs: {item.Price}</div>
                    <div className="product-quantity">Qty: {item.quantity}</div>
                  </div>
                </div>
              ))}
              <div className="order-pricing">
                <hr></hr>
                <p>
                  <span>Subtotal</span>
                  <span>Rs. {cartItems.reduce((total, item) => total + item.Price * item.quantity, 0)}</span>
                </p>
                <p>
                  <span>Shipping</span>
                  <span>Rs. 350</span>
                </p>
                <hr></hr>
                <p>
                  <span>Total</span>
                  <span>Rs. {cartItems.reduce((total, item) => total + item.Price * item.quantity, 0) + 350}</span>
                </p>
                <div className="promo-code">
                  <input type="text" placeholder="Discount or Promo Code" />
                  <button className="btn btn-secondary">Apply</button>
                </div>
              </div>
              <div className="payment-options">
                <h3>Payment options</h3>
                <div className="payment-option">
                  <label>
                    <input
                      type="radio"
                      name="payment"
                      value="Payhere"
                      checked={selectedPaymentMethod === "Payhere"}
                      onChange={() => setSelectedPaymentMethod("Payhere")}
                    />
                    Payhere
                  </label>
                  <br/>
                  <span className="payment-subtext">
                    Pay with payhere payment gateway.
                  </span>
                </div>
                <div className="payment-option">
                  <label>
                    <input
                      type="radio"
                      name="payment"
                      value="Koko"
                      checked={selectedPaymentMethod === "Koko"}
                      onChange={() => setSelectedPaymentMethod("Koko")}
                    />
                    Koko: Buy Now Pay Later
                    <span className="payment-logo">
                      <img src={kokoLogo} alt="Koko" />
                    </span>
                  </label>
                </div>
                <div className="payment-option">
                  <label>
                    <input
                      type="radio"
                      name="payment"
                      value="Mintpay"
                      checked={selectedPaymentMethod === "Mintpay"}
                      onChange={() => setSelectedPaymentMethod("Mintpay")}
                    />
                    Mintpay
                  </label>
                  <div className="payment-logo">
                    <img src={mintpayLogo} alt="Mintpay" />
                  </div>
                </div>
              </div>

              {apiError && <div className="error-message">{apiError}</div>}
              <button
                className="btn btn-success"
                onClick={saveOrder}
                disabled={isLoading}
              >
                {isLoading ? "Placing Order..." : "Place Order"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckoutPage;
