import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  message: '',
  firstNameError: '',
  lastNameError: '',
  emailError: '',
  phoneError: '',
  messageError: '',
};

const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    setFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    setLastName: (state, action) => {
      state.lastName = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setPhone: (state, action) => {
      state.phone = action.payload;
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    },
    setFirstNameError: (state, action) => {
      state.firstNameError = action.payload;
    },
    setLastNameError: (state, action) => {
      state.lastNameError = action.payload;
    },
    setEmailError: (state, action) => {
      state.emailError = action.payload;
    },
    setPhoneError: (state, action) => {
      state.phoneError = action.payload;
    },
    setMessageError: (state, action) => {
      state.messageError = action.payload;
    },
  },
});

export const {
  setFirstName,
  setLastName,
  setEmail,
  setPhone,
  setMessage,
  setFirstNameError,
  setLastNameError,
  setEmailError,
  setPhoneError,
  setMessageError,
} = contactSlice.actions;

export default contactSlice.reducer;
