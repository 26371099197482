const API_BASE_URL = 'https://api.alephaz.com/jb/v1/';
const Image_URL = 'https://api.alephaz.com/jb/public/';
const LOGIN_URL = `${API_BASE_URL}user/auth/login/`;
const SIGNUP_URL = `${API_BASE_URL}user/auth/register/`;
const SAVE_ORDER_URL = `${API_BASE_URL}user/order/save/`;
const UPDATE_ORDER_URL = `${API_BASE_URL}user/order/update/`;
const RESET_PASSWORD_URL = `${API_BASE_URL}user/profile/change-password/`;
const Order_Details_URL = 'https://api.alephaz.com/jb/v1/user/order/all/';
const Get_By_Slug = 'get-product-by-slug/?slug='
const Shipping_Details_URL = `${API_BASE_URL}/user/profile/shipping-details/update/`;
const Related_Products_URL = `${API_BASE_URL}user/products/get-related-products/`;
const BILLING_UPDATE_URL = `${API_BASE_URL}user/profile/billing-details/update/`;
const SHIPPING_UPDATE_URL = `${API_BASE_URL}user/profile/shipping-details/update/`;
const PROFILE_EDIT_URL = `${API_BASE_URL}user/profile/edit/`;
const SEARCH_URL = `${API_BASE_URL}user/products/search/`;
const SEARCH_SUGGESTIONS_URL = `${API_BASE_URL}user/products/search-suggestions/`;



export { 
  API_BASE_URL, 
  Image_URL, 
  LOGIN_URL, 
  SIGNUP_URL, 
  SAVE_ORDER_URL, Order_Details_URL,Get_By_Slug, 
  UPDATE_ORDER_URL, 
  RESET_PASSWORD_URL,
  BILLING_UPDATE_URL,
  PROFILE_EDIT_URL,
  SHIPPING_UPDATE_URL,
  SEARCH_URL,
  SEARCH_SUGGESTIONS_URL,
  Shipping_Details_URL,
  Related_Products_URL
};