import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './Cart.css';
import { updateQuantity, removeItem, setCheckoutData } from '../../../slices/CartSlice';
import { Image_URL } from '../../../config/config';

const Cart = () => {
  const cartItems = useSelector((state) => state.cart.items);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleUpdateQuantity = (id, color, newQuantity) => {
    if (newQuantity > 0) {
      dispatch(
        updateQuantity({ ProductId: id, Color: color, quantity: newQuantity })
      );
    }
  };

  const handleRemoveItem = (id, color) => {
    dispatch(removeItem({ ProductId: id, Color: color }));
  };

  const handleCheckout = () => {
    console.log('Dispatching Checkout Data:', cartItems);
    dispatch(setCheckoutData(cartItems));
    navigate('/checkout');
  };

  const totalAmount = cartItems.reduce((total, item) => total + parseInt(item.Price) * item.quantity, 0);

  return (
    <div className="cart-container">
      <div className="cart-header">
        <h2>Cart</h2>
        <span className="cart-count">{cartItems.length} items</span>
      </div>
      <div className="cart-heading-divider"></div>
      {cartItems.length === 0 ? (
        <p className="no-items-message">No items in cart</p>
      ) : (
        <div className="cart-content">
          <div className="cart-items-container">
            <div className="cart-items">
              {cartItems.map((item) => {
                const firstImage = item.Image;

                return (
                  <div
                    key={`${item.ProductId}-${item.Color}`}
                    className="cart-item"
                  >
                    <div className="item-details">
                      {firstImage && (
                        <img
                          src={Image_URL + "products/thumbnails/" + firstImage}
                          className="main-image"
                          alt={item.Title}
                          onError={(e) => {
                            e.target.src =
                              "https://jbadmin.alephaz.com/assets/img/placeholder-image.jpg";
                          }}
                        />
                      )}
                      <div>
                        <p className="product-title">{item.Title}</p>
                        <p className="item-details-text">Size: {item.Size}</p>
                        <p className="item-details-text">Color: {item.Color}</p>
                        <p>Rs.{item.Price}.00</p>
                      </div>
                    </div>
                    <div className="quantity-control">
                      <button
                        onClick={() =>
                          handleUpdateQuantity(
                            item.ProductId,
                            item.Color,
                            item.quantity - 1
                          )
                        }
                        disabled={item.quantity <= 1}
                      >
                        -
                      </button>
                      <span>{item.quantity.toString().padStart(2, "0")}</span>
                      <button
                        onClick={() =>
                          handleUpdateQuantity(
                            item.ProductId,
                            item.Color,
                            item.quantity + 1
                          )
                        }
                      >
                        +
                      </button>
                    </div>
                    <button
                      className="remove-button"
                      onClick={() => handleRemoveItem(item.ProductId, item.Color)}
                    >
                      <i className="fa-regular fa-trash-can"></i>
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="cart-summary-container">
            <div className="cart-summary">
              <p className="summary-title">
                <strong>Total Amount</strong>
              </p>
              {cartItems.map((item) => (
                <div
                  key={`${item.ProductId}-${item.Color}`}
                  className="summary-item"
                >
                  <span>{item.Title}</span>
                  <span>Rs.{parseInt(item.Price) * item.quantity}.00</span>
                </div>
              ))}
              <hr />
              <div className="summary-total">
                <span>Total</span>
                <span>Rs.{totalAmount}.00</span>
              </div>
              <button className="checkout-button" onClick={handleCheckout}>Checkout</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cart;
