import React from 'react';
import './Footer.css';
import Logo from '../../../assests/logo.png';
import { Footer } from 'react-bootstrap';

const FooterComponent = () => {
  return (
<footer className="footer">
  <div className="container">
    <div className="footer-content">
      <div className="footer-left">
        <img src={Logo} alt="Jewelbox Logo" className="footer-logo" />
        <p className="footer-description">Finest Linen Clothing Brand in Sri Lanka. We offer only the finest and unique dresses.</p>
      </div>
    </div>
    <div className="footer-bottom">
      <hr />
      <div className="footer-bottom-content">
        <p>© 2024 JewelBox. All rights reserved.</p>
        <div className="social-icons">
          <a href="#"><i className="fa-brands fa-facebook"></i></a>
          <a href="#"><i className="fa-brands fa-instagram"></i></a>
          <a href="#"><i className="fa-brands fa-tiktok"></i></a>
          <a href="#"><i className="fa-brands fa-youtube"></i></a>
        </div>
      </div>
    </div>
  </div>
</footer>



  );
};

export default FooterComponent;