import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './SearchModal.css';
import { SEARCH_SUGGESTIONS_URL } from '../../../../config/config';

const SearchModal = ({ show, handleClose }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const fetchSuggestions = useCallback(
    debounce(async (term) => {
      if (term.length > 2) {
        setIsLoading(true);
        try {
          const response = await fetch(`${SEARCH_SUGGESTIONS_URL}?kw=${encodeURIComponent(term)}`);
          if (response.ok) {
            const data = await response.json();
            if (data.status === 1 && Array.isArray(data.data)) {
              setSuggestions(data.data);
            } else {
              setSuggestions([]);
            }
          } else {
            console.error('Failed to fetch suggestions');
            setSuggestions([]);
          }
        } catch (error) {
          console.error('Error fetching suggestions:', error);
          setSuggestions([]);
        } finally {
          setIsLoading(false);
        }
      } else {
        setSuggestions([]);
      }
    }, 300),
    []
  );

  useEffect(() => {
    fetchSuggestions(searchTerm);
  }, [searchTerm, fetchSuggestions]);

  const handleSearch = () => {
    if (searchTerm.trim().length === 0) return;
    navigate(`/search?query=${encodeURIComponent(searchTerm)}`);
    handleClose();
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSuggestionClick = (product) => {
    setSearchTerm(product.Title);
    handleSearch();
  };

  const highlightMatch = (text, highlight) => {
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return parts.map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase() ? (
        <strong key={index}>{part}</strong>
      ) : (
        part
      )
    );
  };

  if (!show) return null;

  return (
    <div className="search-modal-overlay" onClick={handleClose}>
      <div className="search-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="search-input-container">
          <input
            type="text"
            className="search-input"
            placeholder="Search products..."
            autoFocus
            value={searchTerm}
            onChange={handleInputChange}
            aria-label="Search products"
          />
          <button
            className="search-button"
            type="button"
            onClick={handleSearch}
            aria-label="Perform search"
          >
            <i className="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>
        {isLoading && <div className="loading">Loading...</div>}
        {!isLoading && suggestions.length > 0 && (
          <ul className="search-suggestions" role="listbox">
            {suggestions.map((product) => (
              <li
                key={product.ProductId}
                onClick={() => handleSuggestionClick(product)}
                role="option"
              >
                {highlightMatch(product.Title, searchTerm)}
                <span className="product-price"> - ₹{product.Price}</span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default SearchModal;